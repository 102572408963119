.partners {
	&__title {
		&__slim {
			margin-left: vmin(21);
			font-weight: 400;

			@include desktop {
				margin-left: vw(29);
			}
		}

		@include desktop {
			margin-bottom: vw(26);
		}
	}

	&__tabs {
		&__list {
			margin: 0 0 vmin(6);
			font-size: vmin(24);
			line-height: vmin(28);

			ul {
				list-style-type: none;
				overflow: auto;
				margin: 0 vmin(-12);
				padding: 0 vmin(12) vmin(10);
				white-space: nowrap;

				li {
					display: inline-block;
					vertical-align: top;

					+ li {
						margin-left: vw(32);
					}

					a {
						text-decoration: none;
						color: inherit;

						&.is-active {
							font-weight: 700;
						}
					}
				}

				@include desktop {
					margin: 0;
					padding: 0 0 vw(10);
				}
			}

			h2 {
				display: inline;
				margin: 0;
				font-weight: inherit;
			}

			@include desktop {
				margin: 0 0 vw(19);
				font-size: vw(41);
				line-height: vw(41);
			}
		}

		&__content {
			border-top: solid 1px $color-white;
			border-bottom: solid 1px $color-white;
			padding: vmin(32) 0;

			@include desktop {
				padding: vw(30) 0 vw(38);
			}
		}

		&__item {
			display: none;

			&.is-active {
				display: block;
				animation: tabShow $duration-normal;
			}
		}
	}

	&__list {
		&__row {
			@include desktop {
				display: flex;
				margin: 0 vw(-56);
			}
		}

		&__col {
			& + & {
				position: relative;

				&::before {
					content: "";
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					border-top: solid 1px $color-white;

					@include desktop {
						right: auto;
						top: vw(10);
						bottom: vw(2);
						border-top: none;
						border-left: solid 1px $color-white;
					}
				}

				@include mobile {
					margin-top: vmin(32);
					padding-top: vmin(32);
				}
			}

			@include desktop {
				padding: 0 vw(56);
				width: 50%;
			}
		}
	}

	&__item {
		&__logo {
			margin-bottom: vmin(24);

			img {
				height: vmin(52);

				@include desktop {
					height: vw(52);
				}
			}

			@include desktop {
				margin-bottom: vw(26);
			}
		}

		&__description {
			margin-bottom: vmin(8);
			font-weight: 700;
			font-size: vmin(16);
			line-height: vmin(19);

			@include desktop {
				margin-bottom: vw(16);
				font-size: vw(18);
				line-height: vw(21);
			}
		}

		&__text {
			font-size: vmin(14);
			line-height: vmin(16);

			@include desktop {
				font-size: vw(18);
				line-height: vw(21);
			}
		}
	}

	&__slider {
		position: relative;

		.swiper-slide {
			height: auto;
		}

		&__container {
			.swiper-container {
				overflow: visible;
				padding: 0 vmin(36);

				@include desktop {
					overflow: hidden;
					margin: 0 vw(-56) 0 vw(-57);
					padding: 0;
				}
			}

			@include desktop {
				overflow: hidden;
			}
		}

		&__prev,
		&__next {
			position: absolute;
			top: 50%;
			z-index: 5;
			display: flex;
			align-items: center;
			justify-content: center;
			outline: none;
			border: none;
			padding: 0;
			width: vw(24);
			height: vw(24);
			background: none;
			transform: translateY(-50%);

			svg {
				display: block;
				width: vw(16);
				height: vw(32);
				fill: none;
			}
		}

		&__prev {
			left: vw(-8);
		}

		&__next {
			right: vw(-8);
		}

		&__item {
			border-left: solid 1px $color-white;
			padding-right: vmin(32);
			padding-left: vmin(32);
			height: 100%;

			&__logo {
				margin-bottom: vmin(24);

				img {
					height: vmin(46);

					@include desktop {
						height: vw(46);
					}
				}

				@include desktop {
					margin-bottom: vw(24);
				}
			}

			&__text {
				font-size: vmin(14);
				line-height: vmin(16);

				a {
					border-bottom: solid 1px $color-white;
					text-decoration: none;
					color: inherit;

					@include desktop {
						&:hover {
							border-bottom-color: transparent;
						}
					}
				}

				@include desktop {
					font-size: vw(18);
					line-height: vw(21);
				}
			}

			@include desktop {
				padding-right: vw(56);
				padding-left: vw(56);
			}
		}

		@include desktop {
			padding: 0 vw(56);
		}
	}
}

@keyframes tabShow {
	0% {
		opacity: 0;
		transform: translateY(25px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
