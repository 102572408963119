.about {
	position: relative;
	z-index: 10;
	margin: vmin(-25) 0;
	padding: vmin(70) 0 vmin(180);
	color: $color-white;

	&__trigger {
		position: absolute;
		top: 100%;
	}

	&__bg {
		position: absolute;
		left: 50%;
		top: 0;
		z-index: -1;
		width: 110%;
		height: 100%;
		background: linear-gradient(102.95deg, #70e 6.85%, #a200ee 103.66%);
		transform: skewY(4.09deg) translateX(-50%);
		transform-origin: 0% 50%;
		will-change: transform;
	}

	&__canvas {
		position: absolute;
		left: 50%;
		top: 50%;
		z-index: -1;
		width: vmin(350);
		height: vmin(350);
		transform: translate(-50%, -55%);
		pointer-events: none;

		@include desktop {
			width: vw(800);
			height: vw(800);
		}
	}

	&__line-top {
		position: absolute;
		left: 50%;
		top: vmin(10);
		display: flex;
		font-size: vmin(16);
		line-height: vmin(18);
		transform: rotate(4.09deg) translateX(-50%);
		transform-origin: 0 50%;

		ul {
			display: flex;
			flex-shrink: 0;
			list-style-type: none;
			margin: 0;
			padding: 0;
			white-space: nowrap;
			animation: scrollLine linear 120s infinite;
			animation-play-state: paused;
			will-change: transform;

			li {
				flex-shrink: 0;
				margin-right: vmin(16);

				@include desktop {
					margin-right: vw(16);
				}
			}

			.is-animate-lines & {
				animation-play-state: running;
			}
		}

		@include desktop {
			top: vw(15);
			font-size: vw(23);
			line-height: vw(26);
		}
	}

	&__line-bottom {
		position: absolute;
		left: 0;
		bottom: vmin(10);
		display: flex;
		justify-content: flex-end;
		width: 100%;
		font-size: vmin(16);
		line-height: vmin(18);
		transform: rotate(4.09deg);

		ul {
			display: flex;
			flex-shrink: 0;
			list-style-type: none;
			margin: 0;
			padding: 0;
			white-space: nowrap;
			animation: scrollLine2 linear 120s infinite;
			animation-play-state: paused;
			will-change: transform;

			li {
				flex-shrink: 0;
				margin-right: vmin(16);

				@include desktop {
					margin-right: vw(16);
				}
			}

			.is-animate-lines & {
				animation-play-state: running;
			}
		}

		@include desktop {
			bottom: vw(15);
			font-size: vw(23);
			line-height: vw(26);
		}
	}

	&__head {
		&__left {
			margin-bottom: vmin(16);

			@include desktop {
				flex-shrink: 0;
				margin-right: vw(112);
				margin-bottom: 0;
			}
		}

		&__text {
			font-size: vmin(18);
			line-height: vmin(21);

			@include desktop {
				width: 100%;
				font-size: vw(35);
				line-height: vw(40);
			}
		}

		@include desktop {
			display: flex;
		}
	}

	&__title {
		margin-bottom: vmin(7);

		@include desktop {
			margin-bottom: 0;
			margin-left: vw(2);
		}
	}

	&__schedule {
		margin-left: vmin(-10);
		font-weight: 700;
		font-size: vmin(70);
		line-height: vmin(56);

		@include desktop {
			margin-top: vw(-6);
			margin-left: vw(-8);
			font-size: vw(120);
			line-height: vw(139);
		}
	}

	&__slider {
		position: relative;
		margin-top: vmin(92);

		.swiper-slide {
			width: auto;
		}

		@include desktop {
			margin-top: vw(115);
		}
	}

	&__item {
		font-weight: 700;
		font-size: vmin(40);
		line-height: vmin(36);
		letter-spacing: -0.03em;
		-webkit-text-stroke: 1px $color-white;
		color: rgba($color-white, 0);
		transition: 1s;
		cursor: pointer;

		.swiper-slide-active & {
			color: $color-white;
		}

		@include desktop {
			font-size: vw(80);
			line-height: vw(72);
		}
	}

	@include desktop {
		margin: vw(-55) 0;
		padding: vw(150) 0 vw(250);
	}
}

@keyframes scrollLine {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(-100%);
	}
}

@keyframes scrollLine2 {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(100%);
	}
}
