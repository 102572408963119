.directions {
	position: relative;
	padding: vmin(90) 0 vmin(96);
	color: $color-white;
	background: #050024;

	&__list {
		position: relative;
		z-index: 15;

		@include mobile {
			top: vmin(-120);
		}
	}

	&__item {
		position: absolute;
		left: vmin(159);
		top: vmin(190);
		will-change: transform;

		&__link {
			display: block;
			overflow: hidden;
			border-radius: 50%;
			width: vmin(282);
			transition: $duration-normal;

			@include desktop {
				width: vw(344);
			}
		}

		&__image {
			padding-bottom: 100%;
			background: 50% 50% no-repeat;
			background-size: cover;
		}

		&__play {
			position: absolute;
			left: 50%;
			top: 50%;
			width: vmin(30);
			height: vmin(46);
			transform: translate(-50%, -50%);

			@include desktop {
				width: vw(34);
				height: vw(48);
			}
		}

		&:nth-child(2) {
			left: vmin(-65);
			top: vmin(343);
			z-index: 2;

			.directions__item__link {
				width: vmin(243);

				@include desktop {
					width: vw(361);
				}
			}

			@include desktop {
				left: vw(901);
				top: vw(12);
			}
		}

		&:nth-child(3) {
			left: vmin(-47);
			top: vmin(1042);

			.directions__item__link {
				width: vmin(267);

				@include desktop {
					width: vw(320);
				}
			}

			@include desktop {
				left: vw(167);
				top: vw(355);
			}
		}

		&:nth-child(4) {
			left: vmin(50);
			top: vmin(522);

			.directions__item__link {
				width: vmin(327);

				@include desktop {
					width: vw(429);
				}
			}

			@include desktop {
				left: vw(508);
				top: vw(234);
			}
		}

		&:nth-child(5) {
			left: vmin(211);
			top: vmin(863);

			.directions__item__link {
				width: vmin(205);

				@include desktop {
					width: vw(349);
				}
			}

			@include desktop {
				left: vw(938);
				top: vw(404);
			}
		}

		&:nth-child(6) {
			left: vmin(189);
			top: vmin(1136);

			.directions__item__link {
				width: vmin(205);

				@include desktop {
					width: vw(246);
				}
			}

			@include desktop {
				left: vw(395);
				top: vw(650);
			}
		}

		&:nth-child(7) {
			left: vmin(-25);
			top: vmin(806);

			.directions__item__link {
				width: vmin(250);

				@include desktop {
					width: vw(328);
				}
			}

			@include desktop {
				left: vw(677);
				top: vw(697);
			}
		}

		@include desktop {
			left: vw(568);
			top: vw(-121);

			&:hover {
				.directions__item__link {
					transform: scale(1.05);
				}
			}
		}
	}

	&__content {
		margin-bottom: vmin(1000);

		@include desktop {
			margin-bottom: vw(427);
			max-width: vw(392);
		}
	}

	&__title {
		@include desktop {
			margin-bottom: vw(16);
		}
	}

	&__text {
		font-size: vmin(14);
		line-height: vmin(16);

		@include desktop {
			font-size: vw(18);
			line-height: vw(22);
		}
	}

	&__order {
		max-width: vmin(300);
		font-size: vmin(14);
		line-height: vmin(16);

		&__title {
			margin-bottom: vmin(8);
			font-weight: 700;

			@include mobile {
				font-size: vmin(18);
				line-height: vmin(21);
			}

			@include desktop {
				margin-bottom: vw(8);
			}
		}

		&__link {
			margin-top: vmin(24);
			font-weight: 700;
			font-size: vmin(18);
			line-height: vmin(21);
			letter-spacing: -0.1em;

			a {
				text-decoration: none;
				color: inherit;
			}

			@include desktop {
				margin-top: vw(62);
				font-size: vw(23);
				line-height: vw(27);
			}
		}

		@include desktop {
			max-width: vw(392);
			font-size: vw(18);
			line-height: vw(21);
		}
	}

	&__suspended {
		position: relative;
		z-index: 10;
		margin-top: vmin(48);
		padding: vmin(30) vmin(60);
		text-align: center;

		&__border {
			position: absolute;
			left: 0;
			top: 0;
			z-index: -1;
			border: solid 1px $color-white;
			border-radius: vmin(30);
			width: 100%;
			height: 100%;
			transform: skewY(4.09deg);

			@include desktop {
				border-radius: vw(30);
			}
		}

		&__title {
			margin-bottom: vmin(3);
			font-weight: 800;
			font-size: vmin(12);
			line-height: vmin(14);

			@include desktop {
				margin-bottom: vw(3);
				font-size: vw(12);
				line-height: vw(14);
			}
		}

		&__date {
			margin-bottom: vmin(3);
			font-weight: 800;
			font-size: vmin(43);
			line-height: vmin(50);

			@include desktop {
				margin-bottom: vw(3);
				font-size: vw(43);
				line-height: vw(50);
			}
		}

		&__text {
			margin-bottom: vmin(3);
			font-weight: 500;
			font-size: vmin(12);
			line-height: vmin(14);

			@include desktop {
				margin-bottom: vw(3);
				font-size: vw(12);
				line-height: vw(14);
			}
		}

		&__time {
			font-weight: 800;
			font-size: vmin(16);
			line-height: vmin(19);

			@include desktop {
				font-size: vw(16);
				line-height: vw(19);
			}
		}

		@include desktop {
			position: absolute;
			right: vw(49);
			bottom: vw(180);
			margin-top: 0;
			padding: vw(30) vw(35) vw(30) vw(25);
			width: vw(282);
		}
	}

	@include desktop {
		padding: vw(111) 0 vw(356);
	}
}
