@font-face {
	src:
		url("../fonts/LTAmberWide-ExtraexpandedLight.woff2") format("woff2"),
		url("../fonts/LTAmberWide-ExtraexpandedLight.woff") format("woff");
	font-family: "LT Amber Wide";
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	src:
		url("../fonts/LTAmberWide-ExtraexpandedRegular.woff2") format("woff2"),
		url("../fonts/LTAmberWide-ExtraexpandedRegular.woff") format("woff");
	font-family: "LT Amber Wide";
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	src:
		url("../fonts/LTAmberWide-ExtraexpandedMedium.woff2") format("woff2"),
		url("../fonts/LTAmberWide-ExtraexpandedMedium.woff") format("woff");
	font-family: "LT Amber Wide";
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	src:
		url("../fonts/LTAmberWide-ExtraexpandedDemibold.woff2") format("woff2"),
		url("../fonts/LTAmberWide-ExtraexpandedDemibold.woff") format("woff");
	font-family: "LT Amber Wide";
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	src:
		url("../fonts/LTAmberWide-ExtraexpandedBold.woff2") format("woff2"),
		url("../fonts/LTAmberWide-ExtraexpandedBold.woff") format("woff");
	font-family: "LT Amber Wide";
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}
