.m-direction {
	&__head {
		margin-bottom: vmin(16);
		padding-right: vmin(60);

		@include desktop {
			margin-bottom: vw(24);
			padding: 0 vw(100);
			text-align: center;
		}
	}

	&__title {
		margin-bottom: vmin(6);

		@include desktop {
			margin-bottom: vw(6);
		}
	}

	&__description {
		font-size: vmin(18);
		line-height: vmin(21);

		@include desktop {
			font-size: vw(35);
			line-height: vw(40);
		}
	}

	&__slider {
		position: relative;

		&__prev,
		&__next {
			position: absolute;
			top: 50%;
			z-index: 5;
			outline: none;
			border: none;
			padding: 0;
			width: vmin(31);
			height: vmin(61);
			color: $color-white;
			background: none;
			transform: translateY(-50%);

			svg {
				display: block;
				width: 100%;
				height: 100%;

				@include mobile {
					stroke-width: 3px;
				}
			}

			&.swiper-button-disabled {
				display: none;
			}

			@include desktop {
				width: vw(77);
				height: vw(154);
			}
		}

		&__prev {
			left: vmin(10);

			@include desktop {
				left: vw(10);
			}
		}

		&__next {
			right: vmin(10);

			@include desktop {
				right: vw(10);
			}
		}
	}

	&__video {
		position: relative;
		text-align: center;

		&__container {
			position: relative;
			z-index: 5;
			overflow: hidden;
			border-radius: vmin(50);
			text-align: left;

			@include desktop {
				border-radius: vw(160);

				&:hover {
					.m-direction__video__pause {
						opacity: 1;
					}
				}
			}
		}

		video {
			position: relative;
			z-index: 5;
			display: block;
			width: 100%;
		}

		&__play {
			position: absolute;
			left: 0;
			top: 0;
			z-index: 15;
			display: flex;
			align-items: center;
			justify-content: center;
			outline: none;
			border: none;
			padding: 0;
			width: 100%;
			height: 100%;
			background: rgba($color-black, 0.5);

			svg {
				display: block;
				width: vmin(60);
				height: vmin(60);
			}

			.is-play & {
				display: none;
			}
		}

		&__pause {
			position: absolute;
			left: 0;
			top: 0;
			z-index: 15;
			display: none;
			outline: none;
			border: none;
			padding: 0;
			width: 100%;
			height: 100%;
			background: rgba($color-black, 0.2);
			opacity: 0;
			transition: $duration-normal;

			&::before,
			&::after {
				content: "";
				position: absolute;
				left: 50%;
				top: 50%;
				margin-top: vmin(-15);
				border-radius: vw(5);
				width: vw(30);
				height: vw(70);
				background: $color-white;
			}

			&::before {
				margin-left: vw(-40);
			}

			&::after {
				margin-left: vw(10);
			}

			.is-play & {
				display: block;
			}
		}

		&__content {
			margin-top: vmin(16);
			width: 100%;
			font-size: vmin(16);
			line-height: vmin(19);
			text-align: center;

			@include desktop {
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: 15;
				margin-top: 0;
				padding: 0 vw(112) vw(32);
				font-size: vw(18);
				line-height: vw(22);
				pointer-events: none;
			}
		}

		&__title {
			margin-bottom: vmin(16);
			font-weight: 700;

			@include desktop {
				margin-bottom: vw(22);
			}
		}

		&.is-vertical {
			.m-direction__video {
				&__container {
					display: inline-block;
					vertical-align: middle;
					border-radius: vmin(25);

					@include desktop {
						border-radius: vw(80);
					}
				}
			}

			video {
				width: auto;
				height: vmin(300);

				@include desktop {
					height: vw(693);
				}
			}
		}

		&--gradient {
			@include desktop {
				.m-direction__video__container {
					&::after {
						content: "";
						position: absolute;
						left: 0;
						bottom: 0;
						z-index: 10;
						width: 100%;
						height: 50%;
						background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 56, 255, 0.8) 100%);
					}
				}
			}
		}
	}

	&__footer {
		padding-top: vmin(24);
		text-align: center;

		&__text {
			font-size: vmin(18);
			line-height: vmin(22);

			@include desktop {
				font-size: vw(18);
				line-height: vw(22);
			}
		}

		@include desktop {
			margin-top: vw(8);
			padding-top: vw(24);
		}
	}

	&__button {
		margin-top: vmin(24);

		a,
		button {
			display: inline-block;
			vertical-align: middle;
			outline: none;
			border: none;
			padding: 0;
			font-weight: 700;
			font-size: vmin(18);
			line-height: vmin(22);
			letter-spacing: -0.1em;
			color: $color-white;
			background: none;

			@include desktop {
				font-size: vw(23);
				line-height: vw(27);
			}
		}

		@include desktop {
			margin-top: vw(32);
		}
	}
}
