.faq {
	position: relative;
	z-index: 5;
	margin-bottom: vmin(46);

	&__title {
		@include mobile {
			margin-bottom: vmin(25);
		}
	}

	&__canvas {
		position: absolute;
		left: 50%;
		top: 0;
		z-index: -1;
		width: vmin(350);
		height: vmin(350);
		transform: translateX(-50%);
		pointer-events: none;

		canvas {
			width: 100% !important;
			height: 100% !important;
		}

		@include desktop {
			top: vw(-50);
			width: vw(500);
			height: vw(500);
		}
	}

	&__list {
		border-top: solid 1px $color-white;
	}

	&__item {
		border-bottom: solid 1px $color-white;
		padding: vmin(24) 0;

		&__header {
			position: relative;
			padding-right: vmin(32);
			font-size: vmin(18);
			line-height: vmin(21);
			cursor: pointer;

			@include desktop {
				padding-right: vw(70);
				font-size: vw(35);
				line-height: vw(40);
			}
		}

		&__arrow {
			position: absolute;
			right: 0;
			top: vmin(8);
			width: vmin(16);
			height: vmin(9);

			@include desktop {
				right: vw(23);
				top: vw(14);
				width: vw(25);
				height: vw(13);
			}
		}

		&__content {
			display: none;
			padding: vmin(8) 0 0;
			font-size: vmin(14);
			line-height: vmin(16);

			@include desktop {
				padding: vw(16) 0 vw(8);
				font-size: vw(18);
				line-height: vw(21);
			}
		}

		&.is-active &__arrow {
			transform: rotate(180deg);
		}

		@include desktop {
			padding: vw(32) 0;
		}
	}

	@include desktop {
		margin-bottom: vw(41);
	}
}
