.results {
	position: relative;
	z-index: 5;
	padding: vmin(96) 0 vmin(70);
	color: $color-white;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 7.49%, rgba(5, 0, 36, 0.8) 100%);

	&__trigger {
		position: absolute;
		left: 0;
		top: 0;
	}

	&__bg {
		position: absolute;
		left: 0;
		top: 0;
		z-index: -1;
		overflow: hidden;
		width: 100%;
		height: 100vh;

		video {
			position: absolute;
			left: 50%;
			top: 0;
			min-width: 100%;
			min-height: 100%;
			transform: translate(-50%, -15%);
		}
	}

	&__head {
		margin-bottom: vmin(34);
		text-align: center;

		@include desktop {
			margin: 0 auto vw(80);
			max-width: vw(1008);
		}
	}

	&__title {
		padding: 0 vmin(25);

		@include desktop {
			margin-bottom: vw(16);
			padding: 0;
		}
	}

	&__description {
		font-size: vmin(18);
		line-height: vmin(21);

		@include desktop {
			font-size: vw(35);
			line-height: vw(40);
		}
	}

	&__slider {
		position: relative;
		margin: 0 auto vmin(94);
		width: vmin(224);

		&__item {
			&__image {
				border-radius: vmin(66);
				height: vmin(286);
				background: 50% 50% no-repeat;
				background-size: cover;
				filter: grayscale(100%);
				transition: $duration-normal;

				@include desktop {
					border-radius: vw(130);
					height: vw(563);
				}
			}

			// @include desktop {
			// 	&:hover &__image {
			// 		filter: grayscale(0);
			// 	}
			// }
		}

		&__prev,
		&__next {
			position: absolute;
			top: 50%;
			outline: none;
			border: none;
			padding: 0;
			width: vmin(31);
			height: vmin(61);
			color: $color-white;
			background: none;
			transform: translateY(-50%);

			svg {
				display: block;
				width: 100%;
				height: 100%;

				@include mobile {
					stroke-width: 3px;
				}
			}

			@include desktop {
				width: vw(77);
				height: vw(154);
			}
		}

		&__prev {
			left: vmin(-64);

			@include desktop {
				left: vw(-228);
			}
		}

		&__next {
			right: vmin(-64);

			@include desktop {
				right: vw(-228);
			}
		}

		@include desktop {
			margin: 0 auto vw(96);
			width: vw(440);
		}
	}

	&__back-stage {
		position: relative;
		margin: vmin(30) auto 0;
		height: vmin(200);

		&__trigger {
			position: absolute;
			top: 50%;
		}

		&__border {
			position: absolute;
			left: 0;
			top: 0;
			border: solid 2px $color-white;
			border-radius: vmin(30);
			width: 100%;
			height: 100%;
			transform: skewY(4.09deg);
			will-change: transform;

			@include desktop {
				border-width: 1px;
				border-radius: vw(75);
			}
		}

		&__play {
			position: absolute;
			left: 50%;
			top: 50%;
			z-index: 5;
			margin: 0 0 0 vmin(10);
			transform: translate(-50%, -50%);

			svg {
				display: block;
				width: vmin(94);
				height: vmin(146);

				@include desktop {
					width: vw(274);
					height: vw(424);
				}
			}

			&__text {
				position: absolute;
				left: 50%;
				top: 50%;
				margin: 0 0 0 vmin(-10);
				font-weight: 700;
				font-size: vmin(16);
				line-height: vmin(16);
				letter-spacing: -0.1em;
				text-transform: uppercase;
				color: #0038ff;
				transform: translate(-50%, -50%) skewY(5deg);

				@include desktop {
					margin: vw(-5) 0 0 vw(-20);
					font-size: vw(31);
					line-height: vw(36);
				}
			}

			@include desktop {
				margin: 0 0 0 vw(30);
			}
		}

		@include desktop {
			margin: vw(80) auto 0;
			width: vw(1200);
			height: vw(600);
		}
	}

	&__social {
		display: flex;
		justify-content: center;
		margin-top: vmin(30);

		&__list {
			display: flex;
			flex-shrink: 0;
			list-style-type: none;
			margin: 0 vmin(12) 0 0;
			padding: 0;

			li {
				+ li {
					margin-left: vmin(12);

					@include desktop {
						margin-left: vw(32);
					}
				}

				a {
					appearance: none;
					display: block;
					color: $color-white;
					backface-visibility: hidden;
					transition: $duration-normal;

					svg {
						width: vmin(30);
						height: vmin(30);

						@include desktop {
							width: vw(40);
							height: vw(40);
						}
					}

					&.is-vk {
						svg {
							width: vmin(43);

							@include desktop {
								width: vw(60);
							}
						}
					}

					@include desktop {
						&:hover {
							opacity: 0.75;
						}
					}
				}
			}

			@include desktop {
				margin: 0 vw(32) 0 0;
			}
		}

		&__text {
			width: 100%;
			font-size: vmin(14);
			line-height: vmin(16);

			@include desktop {
				max-width: vw(576);
				font-size: vw(18);
				line-height: vw(21);
			}
		}

		@include desktop {
			align-items: center;
			margin: vw(110) auto 0;
			border: dashed 2px $color-white;
			border-radius: vw(30);
			padding: vw(30);
			width: vw(880);
			transform: rotate(4.22deg);
		}
	}

	@include desktop {
		padding: vw(215) 0;
	}
}
