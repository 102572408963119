.footer {
	position: relative;
	z-index: 5;
	margin: vmin(-25) 0;
	padding: vmin(40) 0 vmin(70);
	color: $color-white;

	&__trigger {
		position: absolute;
		left: 0;
		top: 0;
	}

	&__bg {
		position: absolute;
		left: 0;
		top: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		background: url("../images/footer.jpg") 50% 50% no-repeat;
		background-size: cover;
		transform: skewY(-4.09deg);
		transform-origin: 50% 50%;
		will-change: transform;
	}

	.container {
		padding-right: vmin(20);

		@include desktop {
			padding-right: vw(32);
		}
	}

	&__contacts {
		&__title {
			@include desktop {
				margin-bottom: vw(24);
			}
		}

		&__row {
			@include desktop {
				display: flex;
			}
		}

		&__col {
			&:nth-child(1) {
				max-width: vmin(260);

				@include desktop {
					max-width: vw(700);
				}
			}

			& + & {
				margin-top: vmin(16);

				@include desktop {
					margin-top: 0;
					margin-left: vw(90);
				}
			}
		}

		&__text {
			font-size: vmin(18);
			line-height: vmin(21);

			@include desktop {
				font-size: vw(35);
				line-height: vw(40);
			}
		}

		a {
			text-decoration: none;
			color: inherit;

			@include desktop {
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	&__bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: vmin(72);

		@include desktop {
			margin-top: vw(128);
		}
	}

	&__menu {
		list-style-type: none;
		margin: 0;
		padding: 0;
		font-size: vmin(14);
		line-height: vmin(16);

		li {
			+ li {
				margin-top: vmin(4);

				@include desktop {
					margin-top: 0;
					margin-left: vw(12);
				}
			}

			a {
				text-decoration: none;
				color: inherit;

				@include desktop {
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

		@include desktop {
			display: flex;
			font-size: vw(18);
			line-height: vw(21);
		}
	}

	&__social {
		flex-shrink: 0;
		list-style-type: none;
		margin: 0;
		padding: 0;
		text-align: center;

		li {
			+ li {
				margin-top: vmin(2);

				@include desktop {
					margin-top: 0;
					margin-left: vw(32);
				}
			}

			a {
				appearance: none;
				display: block;
				color: $color-white;
				backface-visibility: hidden;
				transition: $duration-normal;

				svg {
					width: vmin(38);
					height: vmin(38);

					@include desktop {
						width: vw(40);
						height: vw(40);
					}
				}

				&.is-vk {
					svg {
						width: vmin(43);

						@include desktop {
							width: vw(60);
						}
					}
				}

				@include desktop {
					&:hover {
						opacity: 0.75;
					}
				}
			}
		}

		@include desktop {
			display: flex;
		}
	}

	@include desktop {
		margin: vw(-55) 0;
		padding: vw(100) 0 vw(100);
	}
}

.footer--static {
	margin: 0;
	padding: vmin(40) 0 vmin(24);

	.footer {
		&__bg {
			transform: none;
		}
	}

	@include desktop {
		padding: vw(80) 0 vw(48);
	}
}
