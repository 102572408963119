.form {
	&__head {
		margin-bottom: vmin(32);
		padding-right: vmin(60);

		@include desktop {
			margin-bottom: vw(50);
			padding-right: 0;
			text-align: center;
		}
	}

	&__title {
		margin-bottom: vmin(6);
		font-weight: 700;
		font-size: vmin(18);
		line-height: vmin(22);
		letter-spacing: -0.1em;

		@include desktop {
			margin-bottom: vw(6);
			font-size: vw(23);
			line-height: vw(27);
		}
	}

	&__description {
		font-size: vmin(16);
		line-height: vmin(19);

		@include desktop {
			font-size: vw(18);
			line-height: vw(22);
		}
	}

	&__input {
		margin-bottom: vmin(16);

		input {
			outline: none;
			border: none;
			border-bottom: solid 1px $color-white;
			padding: 0;
			width: 100%;
			height: vmin(48);
			font-size: vmin(22);
			color: $color-white;
			background: none;
			transition: $duration-normal;

			&::placeholder {
				color: $color-white;
				opacity: 0.4;
			}

			&.error {
				border-block-color: #f00;
			}

			@include desktop {
				height: vw(56);
				font-size: vw(35);
			}
		}

		label.error {
			display: block;
			margin-top: vmin(6);
			font-size: vmin(14);
			line-height: vmin(19);
			color: #f00;

			@include desktop {
				margin-top: vw(10);
				font-size: vw(18);
				line-height: vw(22);
			}
		}

		@include desktop {
			margin-bottom: vw(24);
		}
	}

	&__select {
		position: relative;
		margin-bottom: vmin(16);

		select {
			position: absolute;
			left: 0;
			top: 0;
			z-index: 5;
			outline: none;
			border: none;
			width: 100%;
			height: 100%;
			font-size: vmin(18);
			line-height: vmin(22);
			color: $color-white;
			background: #0c015d;
			opacity: 0;
			cursor: pointer;

			@include desktop {
				font-size: vw(18);
				line-height: vw(22);
			}
		}

		&__label {
			position: relative;
			outline: none;
			border-bottom: solid 1px $color-white;
			padding: vmin(4) 0;
			width: 100%;
			font-size: vmin(22);
			line-height: vmin(40);
			color: rgba($color-white, 0.4);
			background: none;

			&::after {
				content: "";
				position: absolute;
				right: 0;
				top: 50%;
				width: vmin(25);
				height: vmin(13);
				background: url("../images/select-arrow.svg") 50% 50% no-repeat;
				background-size: contain;
				transform: translateY(-50%);

				@include desktop {
					width: vw(25);
					height: vw(13);
				}
			}

			@include desktop {
				font-size: vw(35);
				line-height: vw(40);
			}
		}

		&.is-filled &__label {
			color: $color-white;
		}

		@include desktop {
			margin-bottom: vw(24);
		}
	}

	&__file {
		margin: vmin(40) 0 vmin(32);

		&__head {
			margin-bottom: vmin(16);

			@include desktop {
				margin-bottom: vw(16);
			}
		}

		&__title {
			margin-bottom: vmin(1);
			font-size: vmin(24);
			line-height: vmin(28);

			@include desktop {
				margin-bottom: vw(1);
				font-size: vw(35);
				line-height: vw(40);
			}
		}

		&__description {
			font-size: vmin(18);
			line-height: vmin(21);

			@include desktop {
				font-size: vw(18);
				line-height: vw(21);
			}
		}

		&__item {
			&__upload {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				overflow: hidden;
				border: 1px dashed #c4c4c4;
				border-radius: vmin(30);
				height: vmin(100);
				transition: $duration-normal;

				input {
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					font-size: 0;
					opacity: 0;
					cursor: pointer;
				}

				&__label {
					font-size: vmin(16);
					line-height: vmin(19);

					@include desktop {
						font-size: vw(18);
						line-height: vw(21);
					}
				}

				label.error {
					display: none !important;
				}

				.is-error &,
				.error & {
					border-color: #f00;
					color: #f00;
				}

				@include desktop {
					border-radius: vw(40);
					height: vw(157);

					&:hover {
						background: rgba($color-white, 0.1);
					}
				}
			}

			&__preview {
				display: none;
			}

			&__name {
				font-size: vmin(16);
				line-height: vmin(19);

				@include desktop {
					font-size: vw(18);
					line-height: vw(21);
				}
			}

			&__remove {
				position: relative;
				display: inline-block;
				vertical-align: middle;
				margin-top: vmin(-10);
				margin-left: vmin(16);
				outline: none;
				border: none;
				padding: 0;
				width: vmin(20);
				height: vmin(20);
				background: none;

				&::before,
				&::after {
					content: "";
					position: absolute;
					left: 0;
					top: 50%;
					width: 100%;
					height: 1px;
					background: $color-white;
				}

				&::before {
					transform: rotate(45deg);
				}

				&::after {
					transform: rotate(-45deg);
				}

				@include desktop {
					margin-top: vw(-10);
					margin-left: vw(16);
					width: vw(20);
					height: vw(20);
				}
			}

			&__error {
				display: none;
				margin-top: vmin(6);
				font-size: vmin(14);
				line-height: vmin(19);
				color: #f00;

				.is-error & {
					display: block;
				}

				@include desktop {
					margin-top: vw(10);
					font-size: vw(18);
					line-height: vw(22);
				}
			}

			&.is-active &__upload {
				display: none;
			}

			&.is-active &__preview {
				display: block;
			}

			& + & {
				margin-top: vmin(24);

				&.is-active {
					margin-top: vmin(8);

					@include desktop {
						margin-top: vw(8);
					}
				}

				@include desktop {
					margin-top: vw(32);
				}
			}
		}

		@include desktop {
			margin: vw(56) 0 vw(40);
		}
	}

	&__checkbox {
		margin: 0 auto vmin(40);
		font-size: vmin(16);
		line-height: vmin(19);

		label {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			padding-left: vmin(58);
			cursor: pointer;

			input {
				appearance: none;
				position: absolute;
				left: vmin(17);
				top: vmin(38);
				margin: 0;
				outline: none;
				border: none;
				padding: 0;
				width: 1px;
				height: 1px;
				background: none;
				opacity: 0;

				&:checked ~ {
					i {
						&::before {
							opacity: 1;
							visibility: visible;
						}
					}
				}

				&.error ~ {
					i {
						border-color: #f00;
					}
				}

				@include desktop {
					left: vw(17);
					top: vw(38);
				}
			}

			i {
				position: absolute;
				left: 0;
				top: vmin(4);
				border: solid 1px $color-white;
				width: vmin(34);
				height: vmin(34);

				&::before {
					content: "";
					position: absolute;
					left: 50%;
					top: 50%;
					margin-top: vmin(-3);
					margin-left: vmin(1);
					border-right: solid 1px $color-white;
					border-bottom: solid 1px $color-white;
					width: vmin(10);
					height: vmin(18);
					opacity: 0;
					visibility: hidden;
					transform: translate(-50%, -50%) rotate(45deg);
					transition: $duration-normal;

					@include desktop {
						margin-top: vw(-3);
						margin-left: vw(1);
						width: vw(10);
						height: vw(18);
					}
				}

				@include desktop {
					top: vw(4);
					width: vw(34);
					height: vw(34);
				}
			}

			label.error {
				display: none !important;
			}

			@include desktop {
				padding-left: vw(58);
			}
		}

		a {
			border-bottom: solid 1px $color-white;
			text-decoration: none;
			color: inherit;
			transition: $duration-normal;

			@include desktop {
				&:hover {
					border-bottom-color: transparent;
				}
			}
		}

		@include desktop {
			margin: 0 auto vw(40);
			max-width: vw(450);
			font-size: vw(18);
			line-height: vw(21);
		}
	}

	&__submit {
		margin-top: vmin(40);
		text-align: center;

		button {
			display: inline-block;
			vertical-align: middle;
			overflow: visible;
			outline: none;
			border: none;
			padding: 0 5px;
			font-weight: 700;
			font-size: vmin(18);
			line-height: vmin(22);
			letter-spacing: -0.1em;
			color: $color-white;
			background: none;

			@include desktop {
				font-size: vw(23);
				line-height: vw(27);
			}
		}

		@include desktop {
			margin-top: vw(65);
		}
	}

	@include desktop {
		max-width: vw(560);

		.fancybox-close-small {
			right: vw(-336);
		}
	}
}
