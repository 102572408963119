.header {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 95;
	width: 100%;
	// pointer-events: none;

	&::before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		bottom: vmin(-20);
		z-index: -1;
		width: 100%;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
		opacity: 0.5;
		transition: $duration-normal;
		pointer-events: none;

		@include desktop {
			bottom: vw(-20);
		}
	}

	&__container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: vmin(16) 0;

		@include desktop {
			padding: vw(35) 0;
		}
	}

	&__burger {
		position: relative;
		display: block;
		margin: 0;
		outline: none;
		border: none;
		padding: 0;
		width: vmin(40);
		height: vmin(20);
		background: none;
		pointer-events: all;

		&::before,
		&::after {
			content: "";
			position: absolute;
			left: 0;
			top: 50%;
			width: 100%;
			height: vmin(2);
			background: $color-white;
			transition: $duration-normal;
		}

		&::before {
			transform: translateY(vmin(-4));
		}

		&::after {
			transform: translateY(vmin(3));
		}

		.is-show-menu & {
			&::before {
				transform: translateY(-50%) rotate(45deg);
			}

			&::after {
				transform: translateY(-50%) rotate(-45deg);
			}
		}
	}

	&__menu {
		display: flex;
		list-style-type: none;
		margin: 0;
		padding: 0;
		font-weight: 700;
		font-size: vmin(16);
		line-height: vmin(19);
		pointer-events: all;

		li {
			+ li {
				margin-left: vmin(16);

				@include desktop {
					margin-left: vw(16);
				}
			}

			a {
				text-decoration: none;
				color: $color-white;

				svg {
					color: $color-white;
				}
			}
		}

		@include desktop {
			font-size: vw(23);
			line-height: vw(27);
		}
	}

	&__menu--burger {
		li {
			a {
				@include desktop {
					&:hover {
						color: #e9cb0f;

						&:not(.is-active) {
							svg {
								stroke-dashoffset: 590;
							}
						}
					}
				}
			}
		}

		@include mobile {
			position: absolute;
			left: 0;
			top: 0;
			z-index: -1;
			display: block;
			padding: vmin(50) vmin(12) vmin(50);
			width: 100%;
			opacity: 0;
			visibility: hidden;
			transition: $duration-normal;

			&::before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				z-index: -1;
				width: 100%;
				height: 100%;
				background: linear-gradient(
					to bottom,
					rgba($color-black, 1) 0%,
					rgba($color-black, 1) 63%,
					rgba($color-black, 0) 100%
				);
				opacity: 0.5;
			}

			li {
				+ li {
					margin: vmin(16) 0 0;
				}
			}

			.is-show-menu & {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	&.is-show-shadow {
		&::before {
			opacity: 1;
		}
	}

	@include desktop {
		&:hover {
			&::before {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}
