.become {
	position: relative;
	z-index: 10;
	margin: 0 0 vmin(-25) 0;
	padding: vmin(70) 0 vmin(75);

	&-wrapper {
		position: relative;
		background: #050024;
	}

	&-scroll {
		margin: vmin(-25) 0 0;

		@include desktop {
			margin: vw(-55) 0 0;
		}
	}

	&__trigger {
		position: absolute;
		left: 50%;
		top: vmin(360);

		@include desktop {
			top: vw(288);
		}
	}

	&__bg {
		position: absolute;
		left: 50%;
		top: vw(50);
		z-index: -1;
		width: 110%;
		height: 100%;
		background: linear-gradient(100.26deg, #00ffda 6.84%, #00a3ff 104.11%);
		transform: skewY(4.09deg) translateX(-50%);
		transform-origin: 50% 50%;
		will-change: transform;

		@include desktop {
			top: vw(50);
		}
	}

	&__canvas {
		position: absolute;
		left: 50%;
		top: 45%;
		z-index: -1;
		width: vmin(450);
		height: vmin(450);
		transform: translate(-50%, -50%);
		pointer-events: none;

		@include desktop {
			left: 46.5%;
			top: 50%;
			width: vw(500);
			height: vw(500);
		}
	}

	&__container {
		@include desktop {
			display: flex;
			justify-content: space-between;
			padding-right: vw(56);
		}
	}

	&__left {
		margin-bottom: vmin(369);
		text-align: center;

		@include desktop {
			margin-bottom: 0;
			width: vw(433);
			text-align: left;
		}
	}

	&__right {
		position: relative;
		text-align: center;

		@include desktop {
			width: vw(392);
			text-align: left;
		}
	}

	&__link {
		padding-top: vmin(155);
		font-weight: 700;
		font-size: vmin(18);
		line-height: vmin(21);

		a {
			text-decoration: none;
			color: inherit;
		}

		@include desktop {
			padding-top: 0;
			font-size: vw(18);
			line-height: vw(21);
		}
	}

	&__item {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;

		&__title {
			margin-bottom: vmin(8);
			font-size: vmin(18);
			line-height: vmin(18);

			@include mobile {
				margin-right: auto;
				margin-left: auto;
				max-width: vmin(220);
			}

			@include desktop {
				margin-bottom: vw(18);
				font-size: vw(35);
				line-height: vw(35);
			}
		}

		&__text {
			font-size: vmin(14);
			line-height: vmin(16);

			@include desktop {
				font-size: vw(18);
				line-height: vw(21);
			}
		}
	}

	@include desktop {
		margin: 0 0 vw(-55) 0;
		padding: vw(200) 0;
	}
}
