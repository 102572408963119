.fancybox-bg {
	background: #0c015d;
}

.fancybox-slide--html {
	padding: vmin(30) 0 vmin(60);

	@include desktop {
		padding: vw(30) 0 vw(60);
	}
}

.modal {
	overflow: visible;
	padding: 0;
	width: 100%;
	max-width: vmin(351);
	color: $color-white;
	background: transparent;

	.fancybox-close-small {
		right: 0;
		top: vmin(-15);
		padding: 0;
		width: vmin(60);
		height: vmin(60);
		opacity: 1;

		&::before,
		&::after {
			content: "";
			position: absolute;
			left: 0;
			top: 50%;
			width: 100%;
			height: 1px;
			background: $color-white;
		}

		&::before {
			transform: rotate(45deg);
		}

		&::after {
			transform: rotate(-45deg);
		}

		svg {
			display: none;
		}

		@include desktop {
			top: vw(-10);
			width: vw(90);
			height: vw(90);
		}
	}

	&__text {
		font-weight: 800;
		font-size: vmin(20);
		line-height: vmin(23);

		@include desktop {
			font-size: vw(40);
			line-height: vw(46);
		}
	}

	&__suspended {
		margin-top: vmin(32);

		&__text {
			margin-bottom: vmin(8);
			font-weight: 500;
			font-size: vmin(16);
			line-height: vmin(18);

			@include desktop {
				margin-bottom: vw(8);
				font-size: vw(16);
				line-height: vw(18);
			}
		}

		&__time {
			font-weight: 800;
			font-size: vmin(24);
			line-height: vmin(28);

			@include desktop {
				font-size: vw(24);
				line-height: vw(28);
			}
		}

		@include desktop {
			margin-top: vw(33);
		}
	}

	&--success {
		.form__head {
			+ .form__head {
				margin-top: vw(164);

				@include mobile {
					margin-top: vmin(48);
				}
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		@include mobile {
			text-align: center;
		}
	}

	@include desktop {
		max-width: vw(1232);
	}
}
