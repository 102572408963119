.cookie {
	position: fixed;
	left: 0;
	bottom: 0;
	z-index: 999;
	display: none;
	padding: vmin(16) vmin(12);
	width: 100%;
	color: $color-white;
	background: #5501e4;

	&__container {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__text {
		font-size: vmin(14);
		line-height: vmin(19);

		a {
			border-bottom: solid 1px currentColor;
			text-decoration: none;
			color: inherit;

			@include desktop {
				&:hover {
					border-bottom-color: transparent;
				}
			}
		}

		@include desktop {
			font-size: vw(18);
			line-height: vw(22);
		}
	}

	&__button {
		flex-shrink: 0;

		button {
			display: inline-block;
			vertical-align: middle;
			outline: none;
			border: none;
			border-bottom: solid 1px transparent;
			padding: 0;
			font-weight: 700;
			font-size: vmin(23);
			color: $color-white;
			background: none;

			&:hover {
				border-bottom-color: currentColor;
			}

			@include desktop {
				font-size: vw(23);
			}
		}
	}

	@include desktop {
		padding: vw(24) vw(28);
	}
}
