.page {
	margin: 0 auto;
	padding: vmin(60) 0 vmin(48);
	max-width: vmin(355);
	color: $color-white;

	&__title {
		margin: 0 0 vmin(12);
		font-weight: 700;
		font-size: vmin(24);
		line-height: vmin(28);

		@include desktop {
			margin: 0 0 vw(32);
			font-size: vw(40);
			line-height: vw(46);
		}
	}

	&__text {
		font-size: vmin(14);
		line-height: vmin(19);

		a {
			color: inherit;
		}

		@include desktop {
			font-size: vw(23);
			line-height: vw(26);
		}
	}

	@include desktop {
		padding: vw(120) 0 vw(48);
		max-width: vw(1000);
	}
}
