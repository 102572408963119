*,
*::before,
*::after {
	box-sizing: inherit;
}

html {
	box-sizing: border-box;
	scroll-behavior: smooth;

	&::-webkit-scrollbar {
		width: 0;
		height: 0;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: transparent;
		opacity: 0;
	}
}

body {
	font-family: "LT Amber Wide";
	-webkit-font-smoothing: antialiased;
	text-decoration-skip: objects;
	text-rendering: optimizeLegibility;
	text-size-adjust: 100%;
	-webkit-tap-highlight-color: transparent;
	background: #5c00f2;

	&::-webkit-scrollbar {
		width: 0;
		height: 0;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: transparent;
		opacity: 0;
	}
}

button:not([disabled]),
a[role="button"]:not([disabled]) {
	cursor: pointer;
}

img {
	max-width: 100%;
}
