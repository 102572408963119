.intro {
	position: relative;
	z-index: 5;
	padding: vmin(162) 0 vmin(167);
	color: $color-white;
	background: linear-gradient(180deg, #5c00f2 0%, #02004b 100%);

	&__video-bg {
		position: absolute;
		left: 50%;
		top: 0;
		z-index: -1;
		overflow: hidden;
		width: vmin(480);
		height: vmin(480);
		transform: translateX(-50%);
		pointer-events: none;

		&__canvas {
			position: absolute;
			left: 0;
			top: vmin(-45) !important;
			width: 100%;
			height: 100%;
			transition: $duration-normal * 6;

			.is-preloading & {
				opacity: 0;
				transform: translateY(50px);
			}

			@include desktop {
				top: vw(-220) !important;
			}
		}

		@include desktop {
			width: vw(1440);
			height: vw(1440);
		}
	}

	&__text {
		margin-bottom: vmin(115);
		font-weight: 300;
		font-size: vmin(24);
		line-height: vmin(19);
		text-align: center;
		transition: $duration-normal * 3 $duration-normal * 3;

		.is-preloading & {
			opacity: 0;
			transform: translateY(-25px);
		}

		@include desktop {
			margin: 0 auto vw(46);
			max-width: vw(1045);
			font-size: vw(56);
			line-height: vw(45);
		}
	}

	&__button {
		margin-bottom: vw(24);
		padding-right: vw(24);
		text-align: right;

		a {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			vertical-align: middle;
			border: solid 2px $color-white;
			border-radius: 50%;
			width: vw(175);
			height: vw(175);
			font-weight: 700;
			font-size: vw(18);
			line-height: vw(21);
			text-align: center;
			text-decoration: none;
			color: $color-white;
			transition: $duration-normal;

			&:hover {
				transform: scale(1.1);
			}
		}
	}

	&__video {
		position: relative;
		width: vmin(343);
		height: vmin(269);

		&::before {
			content: "";
			position: absolute;
			left: vmin(6);
			top: vmin(-10);
			width: vmin(345);
			height: vmin(231);
			background: url("../images/intro-shadow.png") 50% 50% no-repeat;
			background-size: 100% 100%;

			@include desktop {
				left: vw(29);
				top: vw(-35);
				width: vw(1203);
				height: vw(724);
			}
		}

		&__video {
			display: block;
			width: 100%;
			height: 100%;
			opacity: 0;
			visibility: hidden;
		}

		&__mask {
			display: none;
		}

		&__canvas {
			position: absolute;
			left: 0;
			top: 0;
			z-index: 5;
			display: block;
			width: 100%;
			height: 100%;
		}

		&__play {
			position: absolute;
			left: 50%;
			top: 50%;
			z-index: 5;
			margin: vmin(-20) 0 0 vmin(20);
			transform: translate(-50%, -50%);

			svg {
				display: block;
				width: vmin(94);
				height: vmin(146);

				@include desktop {
					width: vw(274);
					height: vw(424);
				}
			}

			&__text {
				position: absolute;
				left: 50%;
				top: 50%;
				margin: 0 0 0 vmin(-8);
				font-weight: 700;
				font-size: vmin(12);
				line-height: vmin(16);
				letter-spacing: -0.1em;
				text-transform: uppercase;
				color: #0038ff;
				transform: translate(-50%, -50%) skewY(5deg);

				@include desktop {
					margin: vw(-5) 0 0 vw(-30);
					font-size: vw(31);
					line-height: vw(36);
				}
			}

			@include desktop {
				margin: vw(-60) 0 0 vw(60);
			}
		}

		@include desktop {
			margin-left: vw(56);
			width: vw(1197);
			height: vw(859);
		}
	}

	&__partner {
		position: absolute;
		left: 0;
		bottom: vmin(-20);
		width: vmin(160);

		&__logo {
			margin-bottom: vmin(8);

			img {
				width: vmin(123);

				@include desktop {
					width: vw(173);
				}
			}

			@include desktop {
				margin-bottom: vw(10);
			}
		}

		&__text {
			font-size: vmin(10);
			line-height: vmin(11);

			@include desktop {
				font-size: vw(18);
				line-height: vw(21);
			}
		}

		@include desktop {
			bottom: vw(98);
			width: vw(266);
		}
	}

	@include desktop {
		padding: vw(377) 0 vw(179);
	}
}

.intro--last {
	z-index: 10;

	@include mobile {
		padding-bottom: vmin(300)
	}
}

.intro-video {
	position: fixed;
	left: 0;
	top: 0;
	z-index: -1;
	width: 1px;
	height: 1px;
	object-fit: cover;
	opacity: 0;
}
