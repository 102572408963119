.is-hidden {
	// stylelint-disable-next-line declaration-no-important
	display: none !important;
}

.for-desktop {
	@include mobile {
		// stylelint-disable-next-line declaration-no-important
		display: none !important;
	}
}

.for-mobile {
	@include desktop {
		// stylelint-disable-next-line declaration-no-important
		display: none !important;
	}
}

.clearfix {
	overflow: auto;

	&::after {
		content: "";
		display: table;
		clear: both;
	}
}

.visually-hidden {
	position: absolute;
	overflow: hidden;
	margin: -1px;
	width: 1px;
	height: 1px;
	clip: rect(0 0 0 0);
}

.main {
	overflow: hidden;
}

.container {
	margin: 0 auto;
	width: 100%;
	max-width: vmin(352);

	@include desktop {
		max-width: vw(1344);
	}
}

h2,
.h2 {
	margin: 0 0 vmin(16);
	font-weight: 700;
	font-size: vmin(24);
	line-height: vmin(28);

	@include desktop {
		margin: 0 0 vw(32);
		font-size: vw(41);
		line-height: vw(41);
	}
}

h3,
.h3 {
	margin: 0 0 vmin(16);
	font-weight: 700;
	font-size: vmin(20);
	line-height: vmin(24);

	@include desktop {
		margin: 0 0 vw(32);
		font-size: vw(32);
		line-height: vw(32);
	}
}

.bottom-bg {
	position: relative;
	z-index: 5;
	margin: vmin(-25) 0;
	padding: vmin(70) 0 vmin(82);
	color: $color-white;

	&::before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		background: linear-gradient(180deg, #5c00f2 0%, #02004b 95.07%);
		transform: skewY(4.09deg);
	}

	@include desktop {
		margin: vw(-55) 0 vw(-110);
		padding: vw(198) 0 vw(201);
	}
}

.link-line {
	position: relative;
	z-index: 5;
	text-decoration: none;
	transition: $duration-normal;

	svg {
		position: absolute;
		left: 50%;
		top: 50%;
		z-index: -1;
		width: vmin(152);
		height: vmin(37);
		stroke-dasharray: 590;
		stroke-dashoffset: 590;
		transform: translate(-50%, -50%);
		transition: $duration-normal * 2;
		pointer-events: none;

		@include desktop {
			width: vw(252);
			height: vw(67);
		}
	}

	&.is-active {
		svg {
			stroke-dashoffset: 0;
		}
	}

	@include desktop {
		&:hover {
			svg {
				stroke-dashoffset: 0;
			}
		}
	}
}

.up {
	position: fixed;
	right: vmin(20);
	bottom: vmin(20);
	z-index: 999;
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	border: solid 1px $color-white;
	border-radius: 50%;
	padding: 0;
	width: vmin(56);
	height: vmin(56);
	color: $color-white;
	background: none;
	opacity: 0;
	visibility: hidden;
	transition: $duration-normal;

	svg {
		width: vmin(22);
		height: vmin(12);

		@include desktop {
			width: vw(22);
			height: vw(12);
		}
	}

	.is-show-up & {
		opacity: 1;
		visibility: visible;
	}

	@include desktop {
		right: vw(20);
		bottom: vw(20);
		width: vw(56);
		height: vw(56);

		&:hover {
			color: #3a01b3;
			background: $color-white;
		}
	}
}
