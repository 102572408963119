.not-found {
	position: relative;
	padding: vmin(24) 0 vmin(48);
	text-align: center;
	color: $color-white;
	background: linear-gradient(180deg, #5c00f2 0%, #02004b 100%);

	&__bg {
		position: absolute;
		left: 50%;
		top: vmin(84);
		margin-left: vmin(-227);
		width: vmin(540);
		height: vmin(302);
		background: url("../images/404-bg.png") 50% 50% no-repeat;
		background-size: contain;
		pointer-events: none;

		@include desktop {
			top: vw(90);
			margin-left: vw(-602);
			width: vw(1315);
			height: vw(728);
		}
	}

	&__clip {
		margin-bottom: vmin(-50);
		pointer-events: none;

		img {
			width: vmin(355);

			@include desktop {
				width: vw(712);
			}
		}

		@include desktop {
			margin-bottom: vw(-138);
		}
	}

	&__title {
		margin: 0 0 vmin(12);
		font-weight: 700;
		font-size: vmin(24);
		line-height: vmin(28);

		@include desktop {
			margin: 0 0 vw(16);
			font-size: vw(40);
			line-height: vw(46);
		}
	}

	&__text {
		font-size: vmin(14);
		line-height: vmin(19);

		@include desktop {
			margin: 0 auto;
			max-width: vw(674);
			font-size: vw(23);
			line-height: vw(26);
		}
	}

	&__button {
		margin-top: vmin(31);

		a {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			vertical-align: middle;
			border: solid 2px $color-white;
			border-radius: 50%;
			width: vmin(150);
			height: vmin(150);
			font-weight: 700;
			font-size: vmin(16);
			line-height: vmin(19);
			text-align: center;
			text-decoration: none;
			color: $color-white;
			transition: $duration-normal;

			@include desktop {
				width: vw(175);
				height: vw(175);
				font-size: vw(18);
				line-height: vw(21);

				&:hover {
					transform: scale(1.1);
				}
			}
		}

		@include desktop {
			margin-top: vw(31);
		}
	}

	@include desktop {
		padding: vw(16) 0 vw(48);
	}
}
