.suspended {
	position: relative;
	z-index: 5;
	margin-bottom: vmin(80);
	border: 1px solid $color-white;
	border-radius: vmin(32);
	padding: vmin(24) vmin(32);
	text-align: center;

	&__head {
		margin-bottom: vmin(8);

		@include desktop {
			margin-bottom: vw(8);
		}
	}

	&__title {
		margin-bottom: vmin(8);
		font-weight: 800;
		font-size: vmin(12);
		line-height: vmin(14);

		@include desktop {
			display: inline;
			font-size: vw(32);
			line-height: vw(37);
		}
	}

	&__date {
		font-weight: 800;
		font-size: vmin(40);
		line-height: vmin(46);

		@include desktop {
			display: inline;
			font-size: vw(32);
			line-height: vw(37);
		}
	}

	&__text {
		margin-bottom: vmin(8);
		font-weight: 500;
		font-size: vmin(12);
		line-height: vmin(14);

		@include desktop {
			margin-bottom: vw(8);
			font-size: vw(16);
			line-height: vw(18);
		}
	}

	&__time {
		font-weight: 800;
		font-size: vmin(16);
		line-height: vmin(19);

		@include desktop {
			font-size: vw(24);
			line-height: vw(28);
		}
	}

	@include desktop {
		margin-bottom: vw(128);
		border-radius: vw(32);
		padding: vw(24) vw(148);
	}
}
